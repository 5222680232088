import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { apiMessageConstant } from "../../environments/ApiMessageConstant";
import { UserService } from "../../service/UserService";
import DialogCustom from "../../components/dialog/DialogCustom";
import UserFormWithFormik from "./UserFormWithFormik";
import {MODE} from "../../environments/GlobalConstant";

const User = () => {
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [displayDialogEditUser,setDisplayDialogEditUser]=  useState(false)
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [globalFilter, setGlobalFilter] = useState("");
    const [filterUsers, setfilterUsers] = useState(null);

    const userService = new UserService();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setLoading(true);

        const response = await userService.getAllUsers();

        if (response.success) {
            setUserList(response.object);
            setfilterUsers(response.object);
            setLoading(false);
        } else {
            console.log(apiMessageConstant.ERROR);
            setLoading(false);
        }
    };

    const userBodyTemplate = (rowData) => {
        return rowData.name + " " + rowData.surname;
    };

    const userEmail = (rowData) => {
        return rowData.email;
    };

    const userTelephoneNumber = (rowData) => {
        return rowData.phone;
    };

    const addUser = () => {
        setSelectedUser(null);
        setDisplayDialog(!displayDialog);
    };

    const dialogHide = () => {
        setDisplayDialog(!displayDialog);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Kullanıcı Ekle" icon="pi pi-plus" className="p-button-success mr-2" onClick={addUser} />
            </React.Fragment>
        );
    };
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Kullanıcı Listesi</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editUser(rowData)} />
            </React.Fragment>
        );
    };


    const editUser = (rowData) => {
        setSelectedUser(rowData)
        setDisplayDialogEditUser(!displayDialogEditUser);
    };

    return (
        <div className="grid">
            <Toast ref={toast}></Toast>
            <div className="col-12">
                <div className="card">
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                    <DataTable value={userList} responsiveLayout="scroll" loading={loading}
                      val = {userList}
                      selection={selectedUser}
                      onSelectionChange={(e) => setSelectedUser(e.value)}
                      dataKey="id"
                      paginator
                      rows={10}
                      filters={filterUsers}
                      globalFilterFields={["name"]}
                      rowsPerPageOptions={[5, 10, 25]}
                      className="datatable-responsive"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                      globalFilter={globalFilter}
                      emptyMessage="No User found."
                      header={header}
                    >
                        <Column field="id" header="ID"></Column>
                        <Column body={userBodyTemplate} header="Kullanıcı"></Column>
                        <Column body={userEmail} header="E-posta"></Column>
                        <Column body={userTelephoneNumber} header="Telefon"></Column>
                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: "6rem" }}></Column>
                    </DataTable>
                </div>
            </div>
            <DialogCustom header="Kullanıcı Ekle" displayDialog={displayDialog} dialogHide={addUser}>
                <UserFormWithFormik
                    showResultMessage={true}
                    mode = {MODE.CREATE}
                    close={dialogHide}
                    refreshUserList={loadData}
                />
            </DialogCustom>
            <DialogCustom header="Kullanıcı Düzenle" displayDialog={displayDialogEditUser} dialogHide={editUser}>
                <UserFormWithFormik
                    showResultMessage={true}
                    user={selectedUser}
                    mode = {MODE.UPDATE}
                    close={editUser}
                    refreshUserList={loadData}
                />
            </DialogCustom>


        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(User, comparisonFn);
