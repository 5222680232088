import React from 'react';

export const AppFooter = (props) => {

    return (
        <div className="layout-footer">

            by
            <span className="font-medium ml-2">SESASIS</span>
        </div>
    );
}
