import { pagePath } from '../environments/PagePath';
import { ApiService } from './ApiService';
export class PaymentService {
    _apiService = new ApiService();

    refund(orderId) {
        return this._apiService.post(pagePath.Payment+'/refund?orderId='+orderId).then(res => res)
    }

}
