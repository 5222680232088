import axios from "axios";

import React from 'react';

export async function getIp (){
    const ipRes = await axios.get('https://geolocation-db.com/json/');
    let currency = '';
    let language = '';

    if(ipRes && ipRes.data){
        if (ipRes.data['country_code'] === 'TR'){
            currency = 'TL';
            language = 'tr';
        }else{
            currency = 'EUR';
            language = 'en'
        }
    }
    return {currency,ip:ipRes.data.IPv4,lang:language}
}

/*export function findFlag(name){
    if (name === 'Türkçe'){
        return <Tr fontSize={24}/>
    }else if (name === 'İngilizce'){
        return <Gb fontSize={24}/>
    }else if (name === 'Fransızca'){
        return <Fr fontSize={24}/>
    }else if (name === 'Almanca'){
        return <De fontSize={24}/>
    }else if (name === 'Çince'){
        return <Ch fontSize={24}/>
    }else if (name === 'Japonca'){
        return <Jp fontSize={24}/>
    }
}*/

export function validateYouTubeUrl(urlToParse){
    if (urlToParse) {
        var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (urlToParse.match(regExp)) {
            return true;
        }
    }
    return false;
}

export function validateYouTubeUrlChannel(url){
    if (!url.includes("youtube.com/@")){
        return false;
    }
    return true;
}

export function validateInstagramUrl(url){
    if (!url.includes("instagram.com/")){
        return false;
    }
    return true;
}

export function validateFacebookUrl(url){
    if (!url.includes("facebook.com/")){
        return false;
    }
    return true;
}

export function validateTwitterUrl(url){
    if (!url.includes("twitter.com/")){
        return false;
    }
    return true;
}
