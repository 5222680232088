import React, {useEffect, useState} from 'react';
import FileUploader from "./FileUpload";
import swal from "sweetalert";
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {ProgressSpinner} from "primereact/progressspinner";
import {DoctorService} from "../service/DoctorService";
import {GalleryService} from "../service/GalleryService";

const PhotoGalleriaEdit = ({doctorId}) =>{

    const [pictureUrlList, setPictureUrlList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const doctorService = new DoctorService();
    const galleriaService = new GalleryService();

    useEffect(() =>{
        getDoctorGalleryById(doctorId)
    },[])

    const getDoctorGalleryById = (id)=>{
        setLoading(true);
        galleriaService.galleryList(id)
            .then((res) => {
                if(res&&res.object&&res.object.pictureList){
                    setPictureUrlList(res.object.pictureList);
                }else{
                    setPictureUrlList([]);
                }
                setLoading(false)
            }).catch(e=>{
            console.log(e);
            setLoading(false);
        })
    }

    const uploadDoctorPhoto=(files)=>{
        debugger
        if(!files ||  !files.length > 0){
            swal("Uyarı","Lütfen en az bir fotoğraf seçiniz","warning");
            return;
        }
        setUploadLoading(true);
        galleriaService.uploadPhoto(files,doctorId).then(res=>{
            if (res&&res.success === true){
                getDoctorGalleryById(doctorId);
                setUploadLoading(false);
                swal("Başarılı",res.message,"success");
            }else if(res.message){
                swal("Uyarı",res.message,"warning");
            }
            setUploadLoading(false);
        }).catch(e=>{
            setUploadLoading(false);
        })
    }

    const accept = (id) => {
        galleriaService.deleteImage(id).then(res=>{
            if(res&&res&&res.success === true){
                getDoctorGalleryById(doctorId);
            }else{
                swal("Uyarı",res.message,"warning");
            }
        })
    }

    const reject = () => {

    }

    const confirm = (id) => {
        confirmDialog({
            message: 'Seçilen fotoğrafı silmek istediğinizden emin misiniz',
            header: 'Silme İşlemi Onay',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(id),
            reject,
            acceptLabel:"Evet",
            rejectLabel:"Vazgeç"
        });
    };


    const renderPictures=()=>{
        return(
            pictureUrlList.length>0 ? pictureUrlList.map((picture,index)=>{
                return(
                    <div key={index} className="flex align-items-center flex-wrap">
                        <div className="flex align-items-center"  style={{marginTop:'1%',width:'50%'}}>
                            <img src={picture.url} width={200} height={200} />
                            <Button style={{minWidth:'3rem'}} type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                                    onClick={()=>confirm(picture.id)}
                            />
                        </div>
                        <Divider align="center">
                        </Divider>
                    </div>
                )
            }) : ''
        )
    }

    const renderLoading=()=>{
        return(
            <div className="loading-div">
                <ProgressSpinner style={{width: '50px', height: '50px'}} />
            </div>
        )
    }

    return(
        <div className='row'>
            <ConfirmDialog/>
            <div className='row mt-3'>
                <div className='col-md-12'>
                    <div className="text-center mb-2">
                        <label className="font-bold">FOTOĞRAF EKLEME ALANI</label>
                    </div>
                    {uploadLoading&&renderLoading()}
                    <FileUploader accept="image/*" uploadFiles={uploadDoctorPhoto}/>
                </div>
            </div>
            <Divider align="center">
                <span className="p-tag font-bold">FOTOĞRAFLAR</span>
            </Divider>
            {loading&&renderLoading()}
            <div>
                <span>{pictureUrlList ? `Sisteme kayıtlı (${pictureUrlList.length})  adet fotoğraf` : ''}</span>
            </div>
            <div className='row mt-3'>
                <div className='col-md-12'>
                    {renderPictures()}
                </div>
            </div>
        </div>
    )
}

export default PhotoGalleriaEdit;
