import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {DashboardService} from "../service/DashboardService";
import {DashboardModel} from "./DashboardModel"


const Dashboard = (props) => {
    const [lineOptions, setLineOptions] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [infoDashboard, setInfoDashboard] = useState(DashboardModel)
    const[lastAppointment,setLastAppointment] = useState()

    const dashboardService = new DashboardService();

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };

    useEffect(() => {
        setLoading(true);
        const loadData = async ()=>{
            const res = await dashboardService.getInfo();
            if(res.success) {
                debugger
                setInfoDashboard(res.object);
                setTableDataList(res.object);
                console.log(lastAppointment)
            } else {
                console.log(res.message)
            }
            setLoading(false)
        }
        loadData();
    }, []);

    const  setTableDataList = (data) =>{
        debugger
        setLastAppointment(data.lastAppointmentDTO);
        setTableLoading(false)
        return lastAppointment;
    }

    const DoctorNameTemplate = (rowData) => {
        debugger
        return rowData.doctor.unvan + " " + rowData.doctor.name + " " + rowData.doctor.surname;
    };

    const userNameTemplate = (rowData) => {
        return rowData.userProfile.name + " " + rowData.userProfile.surname
    }

    return (
        <div className="grid">
            {!loading && <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-900 font-bold mb-3">KULLANICI SAYISI</span>
                            <div className="text-900 font-medium text-xl">{infoDashboard.totalUser}</div>

                        </div>
                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: "2.5rem", height: "2.5rem"}}>
                            <i className="pi pi-users text-blue-500 text-xl"/>
                        </div>
                    </div>
                </div>
            </div>}
            {!loading && <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-900 font-bold mb-3">KAZANÇ</span>
                            <div className="text-900 font-medium text-xl">{infoDashboard.totalPrice} ₺</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{width: "2.5rem", height: "2.5rem"}}>
                            <i className="pi pi-dollar text-orange-500 text-xl"/>
                        </div>
                    </div>
                </div>
            </div>}
            {!loading && <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-900 font-bold mb-3">TOPLAM RANDEVU</span>
                            <div className="text-900 font-medium text-xl">{infoDashboard.totalAppointment}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: "2.5rem", height: "2.5rem"}}>
                            <i className="pi pi-inbox text-cyan-500 text-xl"/>
                        </div>
                    </div>
                </div>
            </div>}
            {!loading && <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-900 font-bold mb-3">DOKTOR SAYISI</span>
                            <div className="text-900 font-medium text-xl">{infoDashboard.totalDoctor}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{width: "2.5rem", height: "2.5rem"}}>
                            <i className="pi pi-user text-purple-500 text-xl"/>
                        </div>
                    </div>
                </div>
            </div>}

            {/*{!loading && <div className="col-12 xl:col-6">*/}
            {/*    <div className="card">*/}
            {/*        <h5>Son Randevular</h5>*/}
            {/*        <DataTable rows={8} paginator value={lastAppointment} loading={loading} responsiveLayout="scroll">*/}
            {/*            <Column field="id" header="ID"  style={{width: "35%"}}/>*/}
            {/*            <Column field="name" body={DoctorNameTemplate} header="DOKTOR ADI"/>*/}
            {/*            <Column field="name" body={userNameTemplate} header="HASTA ADI"/>*/}


            {/*        </DataTable>*/}
            {/*    </div>*/}
            {/*    <div className="card">*/}
            {/*        <div className="flex justify-content-between align-items-center mb-5">*/}
            {/*            <h5>En Çok Randevu Alınan Branşlar </h5>*/}

            {/*        </div>*/}

            {/*    </div>*/}
            {/*</div>}*/}

            {/*<div className="col-12 xl:col-6">*/}
            {/*    <div className="card">*/}
            {/*        <div className="flex align-items-center justify-content-between mb-4">*/}
            {/*            <h5>Bildirimler</h5>*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);
