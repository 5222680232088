import React from 'react';
import {useState} from "react";
import {Button} from "primereact/button";
import {PaymentService} from "../../service/PaymentService";
import swal from 'sweetalert';
import {ProgressSpinner} from "primereact/progressspinner";

const RefundForm=({data,refreshAppointments,close})=>{

    const [loading,setLoading] = useState(false);
    const paymentService = new PaymentService();

    const refund = () =>{
        paymentService.refund(data.orderId).then(res=>{
            if (res&&res.success === true){
                swal("Başarılı",res.message,"success");
                refreshAppointments();
                setLoading(false);
                close();
            }else{
                swal("Uyarı",res.message,"warning");
                setLoading(false);
            }
        }).catch(e=>setLoading(false));
    }

    const renderLoading = () => {
        return <ProgressSpinner style={{ width: "30px", height: "30px" }} />;
    };

    return(
        <div style={{display:"flex",justifyContent:"center"}}>
            {loading&&renderLoading()}
            <Button label="İade işlemi başlat" aria-label="Submit" onClick={refund} />
        </div>
    )

}

export default RefundForm;
