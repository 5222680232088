import swal from "sweetalert";
import React, { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';

export const FileUploader= ({uploadFiles,accept}) => {
    const [totalSize, setTotalSize] = useState(0);
    const [files, setFiles] = useState([]);
    const toast = useRef(null);
    const fileUploadRef = useRef(null);

    const onUpload = () => {
        toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
    }

    const onTemplateSelect = (e) => {
        let _newFiles = [...e.files];

        let filtered = _newFiles.filter(item => files.map(e => e.name).includes(item.name));

        if(filtered.length>0){
            swal("Uyarı","Aynı isimli dosya eklemediğinizden emin olun.","warning")
            return;
        }

        setFiles([...files,..._newFiles]);
    }

    const onTemplateUpload = (e) => {
        let files = [...e.files];
        let _totalSize = 0;
        files.forEach(file => {
            _totalSize += (file.size || 0);
        });

        setTotalSize(_totalSize);
        toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
    }

    const onTemplateRemove = (file, callback) => {
        var index = files.indexOf(file);
        if (index !== -1) {
            files.splice(index, 1);
        }

        setTotalSize(totalSize - file.size);
        callback();
    }

    const onTemplateClear = () => {
        setTotalSize(0);
    }

    const headerTemplate = (options) => {
        const { className, chooseButton } = options;

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                <Button type="button" icon="pi pi-fw pi-cloud-upload" className="custom-upload-btn p-button-success p-button-rounded p-button-outlined"
                        onClick={() => uploadFiles(files)} />
            </div>
        );
    }

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{width: '40%'}}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        )
    }

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{'fontSize': '5em', borderRadius: '50%', color: 'var(--surface-d)'}}></i>
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="my-5">Dosyaları Bu Alana Sürükleyebilirsin</span>
            </div>
        )
    }

    const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined'};
    const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
    const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

    return (
        <div>
            <Toast ref={toast}></Toast>

            <Tooltip target=".custom-choose-btn" content="Seç" position="bottom" />
            <Tooltip target=".custom-upload-btn" content="Yükle" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content="Temizle" position="bottom" />

            <div>
                <FileUpload ref={fileUploadRef} name="files"
                            multiple accept={accept}
                            onSelect={onTemplateSelect}
                            headerTemplate={headerTemplate}
                            itemTemplate={itemTemplate}
                            emptyTemplate={emptyTemplate}
                            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
            </div>
        </div>
    )
}

export default FileUploader;
