import {pagePath} from "../environments/PagePath";
import {ApiService} from "./ApiService";

export class GalleryService{
    _apiService = new ApiService();

    galleryList(id){
        return this._apiService.get(pagePath.Gallery+"/galeryList?doctorId="+id).then(res=>res);
    }

    saveYoutubeVideo = (data) => {
        debugger
        return this._apiService.post(pagePath.Gallery+"/saveYoutubeVideo",data).then(res => res);
    }

    uploadVideo = (files,doctorId) => {
        let formData = new FormData();
        debugger
        files.forEach(file=>{
            formData.append('files', file);
        })

        formData.append('doctorId', doctorId);

        return this._apiService.post(pagePath.Gallery+"/saveOrUpdateDoctorMultiVideo_", formData,{
            headers: { 'content-type': 'multipart/form-data' }
        })
    }

    uploadPhoto = (files,doctorId) => {
        let formData = new FormData();

        files.forEach(file=>{
            formData.append('files', file);
        })

        formData.append('doctorId', doctorId);

        return this._apiService.post(pagePath.Gallery+"/saveOrUpdateDoctorMultiPhoto", formData,{
            headers: { 'content-type': 'multipart/form-data' }
        })
    }

    deleteImage(id){
        return this._apiService.delete(pagePath.Gallery+"/deleteImage?imageId="+id)
    }

    deleteVideo(id){
        return this._apiService.delete(pagePath.Gallery+"/deleteVideo?videoId="+id)
    }
}
