import React, {useEffect, useState} from 'react';
import FileUploader from "./FileUpload";
import swal from "sweetalert";
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {ProgressSpinner} from "primereact/progressspinner";
import {InputText} from "primereact/inputtext";
import {GalleryService} from "../service/GalleryService";
import {validateYouTubeUrl} from "../util/util";

const VideoGalleryEdit = ({ doctorId}) =>{

    const [pictureUrlList, setPictureUrlList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [youtubeLink, setYoutubeLink] = useState('');
    const [uploadLoading, setUploadLoading] = useState(false);

    const galleriaService = new GalleryService();

    useEffect(() =>{
        getDoctorGalleryById(doctorId)
    },[])

    const getDoctorGalleryById = (id)=>{
        setLoading(true);
        galleriaService.galleryList(id)
            .then((res) => {
                if(res&&res.object&&res.object.videoList){
                    setPictureUrlList(res.object.videoList);
                }else{
                    setPictureUrlList([]);
                }
                setLoading(false)
            }).catch(e=>{
            console.log(e);
            setLoading(false);
        })
    }

    const uploadDoctorVideo=(files)=>{
        if(!files ||  !files.length > 0){
            swal("Uyarı","Lütfen en az bir video seçiniz","warning");
            return;
        }
        setUploadLoading(true);
        galleriaService.uploadVideo(files,doctorId).then(res=>{
            debugger
            if (res&&res.success === true){
                getDoctorGalleryById(doctorId);
                setUploadLoading(false);
                swal("Başarılı",res.message,"success");
            }else if(res.message){
                setUploadLoading(false);
                swal("Uyarı",res.message,"warning");
            }
        }).catch(e=>{
            setUploadLoading(false);
        })
    }

    const accept = (id) => {
        galleriaService.deleteVideo(id).then(res=>{
            if(res&&res&&res.success === true){
                getDoctorGalleryById(doctorId);
            }else{
                swal("Uyarı",res.message,"warning");
            }
        })
    }

    const reject = () => {

    }

    const confirm = (id) => {
        confirmDialog({
            message: 'Seçilen videoyu silmek istediğinizden emin misiniz',
            header: 'Silme İşlemi Onay',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(id),
            reject,
            acceptLabel:"Evet",
            rejectLabel:"Vazgeç"
        });
    };


    const renderPictures=()=>{
        return(
            pictureUrlList.length>0 ? pictureUrlList.map(video=>{
                return(
                    <div className="flex flex-wrap">
                        <div className="flex align-items-center"  style={{marginTop:'1%',width:'50%'}}>
                            <iframe title='doctor-video' src={video.url} className='doctor-video'/>
                            <Button style={{minWidth:'3rem'}} type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-5"
                                    onClick={()=>confirm(video.id)}
                            />
                        </div>
                        <Divider align="center">
                        </Divider>
                    </div>
                )
            }) : ''
        )
    }

    const renderLoading=()=>{
        return(
            <div className="loading-div">
                <ProgressSpinner style={{width: '50px', height: '50px'}} />
            </div>
        )
    }

    const addYoutubeLink=()=>{
        if (!youtubeLink){
            swal("Uyarı","Lütfen youtube linki girin.","warning");
            return;
        }

        if (!validateYouTubeUrl(youtubeLink)){
            swal("Uyarı","Lütfen geçerli bir youtube linki girin.","warning");
            return;
        }

        galleriaService.saveYoutubeVideo({
            doctorId,
            youtubeLink
        }).then(res=>{
            debugger
            if(res&&res.success===true){
                setYoutubeLink('');
                getDoctorGalleryById(doctorId);
                setUploadLoading(false);
                swal("Başarılı",res.message,"success");

            }else{
                if (res.message){
                    swal("Uyarı",res.message,"warning");
                }
            }
        })

    }

    return(
        <div className='row'>
            <ConfirmDialog/>
            <div className='row mt-3'>
                <div className='col-md-12'>
                    <div className="text-center mb-2">
                        <label className="font-bold">VİDEO EKLEME ALANI</label>
                    </div>
                    {uploadLoading&&renderLoading()}
                    <FileUploader accept="video/mp4,video/x-m4v,video/*" uploadFiles={uploadDoctorVideo}/>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-md-12'>
                    <div>
                        <label>Youtube linki</label>
                    </div>

                    <InputText
                        value={youtubeLink}
                        onChange={(e) => setYoutubeLink(e.target.value)}
                    />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-md-12'>
                    <Button type="button" label="Ekle" className="p-button-outlined p-button-primary"
                            onClick={addYoutubeLink}
                    />
                </div>
            </div>
            <Divider align="center">
                <span className="p-tag font-bold">VİDEOLAR</span>
            </Divider>
            {loading&&renderLoading()}
            <div>
                <span>{pictureUrlList ? `Sisteme kayıtlı (${pictureUrlList.length})  adet video` : ''}</span>
            </div>
            <div className='row mt-3'>
                <div className='col-md-12'>
                    {renderPictures()}
                </div>
            </div>
        </div>
    )
}

export default VideoGalleryEdit;
