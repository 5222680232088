import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import DialogCustom from "../../components/dialog/DialogCustom";
import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { apiMessageConstant } from "../../environments/ApiMessageConstant";
import { DoctorService } from "../../service/DoctorService";
import DoctorForm from "./DoctorForm";
import DoctorModel from "./DoctorModel";
import "./doctor.css"

const Doctor = () => {
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [displayDialogEditDoctor, setDisplayDialogEditDoctor] = useState(false);
    const [doctorList, setDoctorList] = useState(null);
    const [filterDoctors, setfilterDoctors] = useState(null);

    const [globalFilter, setGlobalFilter] = useState("");
    const dt = useRef(null);

    const doctorService = new DoctorService();
    const [doctor, setDoctor] = useState(DoctorModel);


    const loadData = async () => {
        setLoading(true);
        const responese = await doctorService.getAllDoctors();
        if (responese.success) {
            setDoctorList(responese.object);
            setfilterDoctors(responese.object);
            setLoading(false);
        } else {
            console.log(apiMessageConstant.ERROR);
            toast.current.show({ severity: "error", summary: "Mesaj", detail: apiMessageConstant.ERROR });
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const doctorBodyTemplate = (rowData) => {
        return rowData.unvan + " " + rowData.name + " " + rowData.surname;
    };

    const doctorMail = (rowData) => {
        return rowData.email;
    };

    const doctorId = (rowData) => {
        return rowData.id;
    };

    const doctorBranch = (rowData) => {
        return rowData.branch?.birimAdi;
    };

    const perPriceWithTl = (rowData) => {
        return rowData.perPriceWithTl;
    };

    const quePriceWithTl = (rowData) => {
        return rowData.quePriceWithTl;  //seans euro ücreti
    };

    const doctorTelephoneNumber = (rowData) => {
        return rowData.phone;
    };

    const doctorApproved = (rowData) => {
        if (rowData.approved) return <span className={`product-badge status-odendi`}>ONAYLANDI</span>;
        else return <span className={`product-badge status-odenmedi`}>ONAYLANMADI</span>;
    };

    const editDoctor = (rowData) => {
        setDoctor(rowData)
        setDisplayDialogEditDoctor(true);
    };

    const doktorEkle = () => {
        setDisplayDialog(!displayDialog);
    };

    const dialogHide = () => {
        setDisplayDialog(!displayDialog);
    };
    const dialogHideEditDoctor = () => {
        setDisplayDialogEditDoctor(!displayDialogEditDoctor);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editDoctor(rowData)} />
            </React.Fragment>
        );
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Yeni Doktor Ekle" icon="pi pi-plus" className="p-button-success mr-2" onClick={doktorEkle} />
                </div>

                <div className="my-2">
                    <Button label="Tüm Doktorlar Listesi" icon="pi" className="p-button-help" onClick={filterAllDoctor} />
                </div>
                <div className="my-2 m-2">
                    <Button label="Onay Bekleyen Doctorlar" icon="pi" className="p-button-warning" onClick={approvedDoctor} />
                </div>
            </React.Fragment>
        );
    };
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Doktor Listesi</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Doktora göre ara..." />
            </span>
        </div>
    );

    const filterAllDoctor = () => {
        setDoctorList(filterDoctors);
    };

    const approvedDoctor = () => {
        setDoctorList(filterDoctors.filter((x) => x.approved === false));
    };

    return (
        <div className="col-12">
            <div className="grid">
                            <Toast ref={toast}></Toast>
                            <div className="col-12">
                                <div className="card">
                                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                                    <DataTable
                                        ref={dt}
                                        value={doctorList}
                                        dataKey="id"
                                        paginator
                                        rows={10}
                                        filters={filterDoctors}
                                        globalFilterFields={["unvan","name","surname"]}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        className="datatable-responsive"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                                        globalFilter={globalFilter}
                                        emptyMessage="No User found."
                                        header={header}
                                        responsiveLayout="scroll"
                                        sortField="id"
                                        loading={loading}
                                    >
                                        <Column field="id" body={doctorId} header="Id"></Column>
                                        <Column field="name" body={doctorBodyTemplate} header="Doktor"></Column>
                                        <Column body={doctorBranch} header="Birim"></Column>
                                        <Column body={doctorMail} header="Mail"></Column>
                                        <Column body={perPriceWithTl} header="Seans Ücret (TL)"></Column>
                                        <Column body={quePriceWithTl} header="Soru Ücret (TL)"></Column>
                                        <Column body={doctorTelephoneNumber} header="Telefon Numarası"></Column>
                                        <Column body={doctorApproved} header="Onay Durumu"></Column>
                                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: "6rem" }}></Column>
                                    </DataTable>
                                </div>
                            </div>
                            <DialogCustom header="Doktor Ekle" displayDialog={displayDialog} dialogHide={dialogHide}>
                                <DoctorForm doctor={doctor}
                                            close={dialogHide}
                                            mode={"CREATE"}
                                            refreshData={loadData}>
                                </DoctorForm>
                            </DialogCustom>
                            <DialogCustom header="Doktor Güncelle"
                                          displayDialog={displayDialogEditDoctor}
                                          dialogHide={dialogHideEditDoctor}>
                                <DoctorForm doctor={doctor}
                                            mode={"UPDATE"}
                                            close={dialogHideEditDoctor}
                                            refreshData={loadData}
                                            isUpdate={true}>
                                </DoctorForm>
                            </DialogCustom>
                        </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Doctor, comparisonFn);
