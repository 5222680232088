import {Calendar} from "primereact/calendar";
import React, {useEffect, useState} from "react";
import {addLocale} from "primereact/api";
import tr from "../../locale/tr.json";
import './date-and-time-picker.css'
import { Card } from 'primereact/card';
import {DoctorService} from "../../service/DoctorService";
import {DoctorCalendarService} from "../../service/DoctorCalendarService";
import {HOURS, MODE} from "../../environments/GlobalConstant";

const DateAndTimePicker = ({mode,selectedDate,selectedHour,doctorId,setSelectedHour,setSelectedDoctorCalendarId,setSelectedDate}) => {

    addLocale('tr', tr);

    const today = new Date();

    const [doctorCalendar,setDoctorCalendar] = useState([]);
    const [viewDate, setViewDate] = useState(mode === MODE.UPDATE ? selectedDate : new Date());
    const [availableDays, setAvailableDays] = useState([]);

    const doctorCalendarService = new DoctorCalendarService();
    const doctorService = new DoctorService();

    function getDifference(a, b) {
        return a.filter(element => {
            return !b.includes(element);
        });
    }

    /*useEffect(()=>{
        if (selectedHour){
            if (doctorCalendar.length > 0 ){
                let newDoctorCalendar = [];
                doctorCalendar.forEach(e=>{
                    if (e.hour === selectedHour){
                        e.isSelected = true;
                    }else{
                        if (e.isSelected){
                            e.disabled = false;
                        }
                        e.isSelected = false;
                    }
                    newDoctorCalendar.push(e);
                })
                setDoctorCalendar(newDoctorCalendar);
            }
        }
    },[selectedHour])*/

    useEffect(() => {
        if (viewDate){
            let now = new Date(viewDate);
            let year = now.getFullYear();
            let month = now.getMonth() + 1;

            let request={
                "doctorId": doctorId,
                "year": year,
                "month": month
            }

            doctorService.getDoctorCalendarList(request).then(res=>{
                if (res&&res.object){
                    setAvailableDays(res.object);
                }
            })
        }

    },[viewDate]);

    const findDoctorScheduler = selected =>{

        let selectedDateObj;
        if (selected.value){
            selectedDateObj = new Date(selected.value);
        }else{
            selectedDateObj = new Date(selected);
        }

        if (isNaN(selectedDateObj)){
            selectedDateObj = new Date();
        }

        let day = selectedDateObj.getDate();
        let month = selectedDateObj.getMonth() + 1;
        let year = selectedDateObj.getFullYear();

        let request={
            "doctorId": doctorId,
            "year": year,
            "month": month,
            "day": day
        }

        setSelectedDate(selectedDateObj);

        doctorCalendarService.getDoctorAvailableHours(request).then(res=>{
            if (res && res.object.length > 0){
                let matchesHours = [];
                let result=[];
                res.object.map(doctorCalendar => {
                    let doctorCalendarSplit = doctorCalendar.hour.split(':');
                    let splittedHour='';
                    if (doctorCalendarSplit.length>0){
                        splittedHour = doctorCalendarSplit[0] + ':' + doctorCalendarSplit[1];
                    }
                    let filterResult = HOURS.filter(hour => hour == splittedHour);
                    if (filterResult.length>0){
                        doctorCalendar.disabled = false;
                        matchesHours.push(filterResult[0]);
                    }else{
                        doctorCalendar.disabled = true;
                    }
                    doctorCalendar.hour = splittedHour;
                    result.push({hour:doctorCalendar.hour,disabled:doctorCalendar.disabled,id: doctorCalendar.id});
                });

                let notMatchHour = getDifference(HOURS,matchesHours);
                notMatchHour.map(e=>{
                    if (e === selectedHour){
                        result.push({hour:e,disabled:true});
                    }else{
                        result.push({hour:e,disabled:true});
                    }
                });

                var collator = new Intl.Collator([], {numeric: true});
                setDoctorCalendar(result.sort((a, b) => collator.compare(a.hour, b.hour)));
            }else{
                let result = [];
                HOURS.map(e=>{
                    result.push({hour:e,disabled:true});
                })
                setDoctorCalendar(result);
            }
        })
    }


    useEffect(()=>{
        if (doctorId){
            if (mode === MODE.CREATE){
                findDoctorScheduler(today);
            }else {
                findDoctorScheduler(viewDate);
            }
        }
    },[doctorId])

    const addSelectedHour= (hour,id) =>{
        setSelectedHour(hour);
        setSelectedDoctorCalendarId(id);
    }

    const dateTemplate = (date) => {
        if (availableDays.filter(e => e.day === date.day && e.month === date.month + 1 && e.year === date.year).length > 0 && compareDates(date)) {
            return (
                <div className='doctor-calendar-available-day fw-bold'>
                    <div className='row'>
                    </div>
                    <div className='row'>
                        {date.day}
                    </div>
                </div>
            );
        }
        else {
            return date.day;
        }
    };

    const compareDates=(date)=>{
        let dateIsLaterFromToday=false;

        let todayMonth = today.getMonth() + 1;
        let todayYear =  today.getFullYear();

        if (date.year > todayYear){
            dateIsLaterFromToday = true;
        }else if ((date.year == todayYear) && (date.month +1 > todayMonth)){
            dateIsLaterFromToday = true;
        }else if((date.month +1 == todayMonth) && (date.day >= today.getDate())){
            dateIsLaterFromToday = true;
        }

        return dateIsLaterFromToday;
    }

    return(
        <div style={{width:'100%'}}>
            <div>
                <Calendar minDate={today} maxDate={new Date(new Date().setDate(today.getDate() + 30))}
                          className='doctor-calendar' inline locale={'tr'}
                          dateTemplate={dateTemplate}
                          viewDate={viewDate}
                          onViewDateChange={(e) => {
                              setViewDate(e.value);
                          }}
                          onChange={(e)=>{
                              findDoctorScheduler(e);
                              if (mode === MODE.UPDATE){
                                  setSelectedHour(null);
                              }
                          }} />
            </div>
            <div>
            </div>
            <Card title="Lütfen aşağıdan randevu saatini seçin" style={{ fontSize:'inherit', width: '100%', marginBottom: '2em',marginTop:'1%' }}>
                <div className="hour-area">
                    {doctorCalendar.map((doctorCalendar,index)=>{
                    return (
                            doctorCalendar.disabled === true ?
                                <div className="hour-item-disabled" key={"itemdisabled"+index}>
                                    <span>{doctorCalendar.hour}</span>
                                 </div> :
                                <div className="hour-item" key={"item"+index}>
                                    <span onClick={() => addSelectedHour(doctorCalendar.hour,doctorCalendar.id)}>{doctorCalendar.hour}</span>
                                </div>
                    )
                })}
             </div>
            </Card>
        </div>
    )
}

export default DateAndTimePicker;
