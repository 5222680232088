
import { apiPath } from '../environments/ApiPath';
import { pagePath } from '../environments/PagePath';
import { ApiService } from './ApiService';
import axios from 'axios';
export class UserService {
    _apiService = new ApiService();

    getAllUsers() {
        return this._apiService.get(pagePath.User+'/getAll').then(res => res)
    }

    saveUser(data){
        return this._apiService.post(pagePath.User+'/save',data).then(res => res)
    }

    deleteUser(data){
        return this._apiService.get(pagePath.User+'/delete?id='+data).then(res => res)
    }

    login(data) {
        return axios.post(apiPath.API_LOGIN_PATH+"/auth/signin",data).then(res => res.data);
    }

    updateUser(data){
        return this._apiService.post(pagePath.User+'/update',data).then(res => res)
    }

    updateProfilePhoto = (file,userProfileId) => {
        let formData = new FormData();

        formData.append('profilePhoto', file);
        formData.append('userProfileId', userProfileId);

        return this._apiService.post(pagePath.User+"/updateProfilePhoto", formData,{
            headers: { 'content-type': 'multipart/form-data' }
        })
    }

    findById(id){
        return this._apiService.get(pagePath.User+'/findById?userProfileId='+id).then(res => res)
    }

    generatePassword(userProfileId){
        return this._apiService.get(pagePath.User+'/generatePassword?userProfileId='+userProfileId).then(res => res)
    }

}
