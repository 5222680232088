export const pagePath = {
SAVE:'/save',
GETBYID: '/getById',
GETALL:'/getAll',


//component
Doctor: '/doctor',
User:   '/user',
Appointment:'/appointment',
Branch:'/branch',
Dashboard:'/dashboard',
Gallery : "/doctorGallery",
Parameter: 'parameter',
Payment: 'payment',
UserProfile: 'userprofile',
DoctorCalendar: '/doctorcalendar',

}
