import { apiPath } from "../environments/ApiPath";
import axios from "axios";

export class ApiService {
  http = axios.create({
    baseURL: apiPath.API_BASE_PATH,
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') ,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'}
  });



  get(url) {
    return this.http.get(url).then(res => res.data)
  }

  post(url, data) {
    return this.http.post(url, data).then(res => res.data)
  }

  put(url, data) {
        return this.http.put(url, data).then(res => res.data)
  }

  delete(url) {
        return this.http.delete(url).then(res => res.data)
  }
}

