import { pagePath } from '../environments/PagePath';
import { ApiService } from './ApiService';

export class DoctorService {

    _apiService = new ApiService();

    getAllDoctors() {
        return this._apiService.get(pagePath.Doctor+'/getAll').then(res => res)
    }

    getAllDoctorsByBranchId(branchId) {
        return this._apiService.get(pagePath.Doctor+'/getAllByBranchId?branchId='+branchId).then(res => res)
    }

    saveDoctor(data){
        return this._apiService.post(pagePath.Doctor+'/save',data).then(res => res)
    }

    updateDoctor(data){
        return this._apiService.put(pagePath.Doctor+'/update',data).then(res => res)
    }

    deleteDoctor(id){
        return this._apiService.delete(pagePath.Doctor+'/delete?doctorId='+id).then(res => res)
    }

    generatePassword(data){
        return this._apiService.get(pagePath.Doctor+'/generatePassword?id='+data).then(res => res)
    }

    approveDoctor(id) {
        return this._apiService.get(pagePath.Doctor+'/approve?id='+id).then(res => res)
    }

    updateProfilePhoto = (file,doctorId) => {
        let formData = new FormData();

        formData.append('profilePhoto', file);
        formData.append('doctorId', doctorId);

        return this._apiService.post(pagePath.Doctor+"/updateProfilePhoto", formData,{
            headers: { 'content-type': 'multipart/form-data' }
        })
    }

    getByid(id){
        return this._apiService.get(pagePath.Doctor+'/getByid?doctorId='+id).then(res => res)
    }

    getDoctorCalendarList = (data) => {
        return this._apiService.post(pagePath.Doctor+'/doctorcalendardaylist',data).then(res => res)
    }

}
