import { pagePath } from '../environments/PagePath';
import { ApiService } from './ApiService';
export class AppointmentService {
    _apiService = new ApiService();

    getAllAppointments(data) {
        return this._apiService.post(pagePath.Appointment+'/getAll',data).then(res => res)
    }

    save(data){
        return this._apiService.post(pagePath.Appointment+'/save',data).then(res => res)
    }

    update(data){
        return this._apiService.post(pagePath.Appointment+'/update',data).then(res => res)
    }

    deleteAppointment(data){
        return this._apiService.get(pagePath.Appointment+'/delete?id='+data).then(res => res)
    }

}
