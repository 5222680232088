import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "./Dialog.css";

const DialogCustom = (props) => {
    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Kapat" icon="pi pi-times" onClick={props.dialogHide} className="p-button-text" />
            </div>
        );
    };

    return (
        <div>
            <Dialog header={props.header} visible={props.displayDialog} style={ window.innerWidth <= 768 ? {  width: '100vw' } : {width: '60vw'}} footer={renderFooter("dialog")} onHide={props.dialogHide}>
                {props.children}
            </Dialog>
        </div>
    );
};

export default DialogCustom;
