export const apiPath = {

//API_BASE_PATH: 'http://localhost:8080/api/admin',
API_BASE_PATH: 'https://ec2-18-157-160-59.eu-central-1.compute.amazonaws.com/api/admin',
//API_BASE_PATH : 'http://192.168.1.108:8181/api',
API_LOGIN_PATH:'https://ec2-18-157-160-59.eu-central-1.compute.amazonaws.com/api',
//API_LOGIN_PATH:'http://localhost:8080/api',
//APP_BASE_PATH: 'http://localhost:3000/apps',
APP_ROOT_PATH: '/apps',
}
