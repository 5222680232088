import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { format } from 'date-fns'
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Ripple } from "primereact/ripple";
import React, { useState, useEffect, useRef } from "react";
import DialogCustom from "../../components/dialog/DialogCustom";
import AppoinmentForm from "../../components/forms/AppointmentForm";
import { apiMessageConstant } from "../../environments/ApiMessageConstant";
import { AppointmentService } from "../../service/AppointmentService";
import { classNames } from "primereact/utils";
import PaymentForm from "../../components/payment/PaymentForm";
import {MODE} from "../../environments/GlobalConstant";
import RefundForm from "../../components/payment/RefundForm";
import {useHistory, useLocation} from 'react-router-dom';
import {Dialog} from "primereact/dialog";

const Appointment = () => {
    const [appointmentList, setAppointmentList] = useState([]);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const [showRefundDialog, setShowRefundDialog] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({});

    const appointmentService = new AppointmentService();
    const [selectedAppoinment, setSelectedAppoinment] = useState(null);
    const [totalPage, setTotalPage] = useState(0);
    const [errMsg, setErrMsg] = useState(null);
    const [mode, setMode] = useState(MODE.CREATE);
    const location = useLocation();
    const history = useHistory()
    const [showPaymentFailMessage, setShowPaymentFailMessage] = useState(false);

    const renderFailMessage=()=>{
        if (showPaymentFailMessage && errMsg){
            return (
                <Dialog
                    visible={showPaymentFailMessage}
                    style={{ width: '50vw',minHeight: '15vw' }}
                    onHide={() => setShowPaymentFailMessage(false)}
                >
                    <div style={{display:"flex",flexDirection:"column"}}>
                        <div style={{display:"flex",flex:1}}>
                            <div style={{display:"flex",width:"20%",alignItems:"center",justifyContent:"center"}}>
                                <i className="fa-solid fa-circle-exclamation" style={{fontSize:"100px",color:"red"}}></i>
                            </div>
                            <div style={{display:"flex",width:"80%"}}>
                                <div style={{display:"flex",flexDirection:"column",flex:1}}>
                                    <div style={{borderBottom:'1px solid black',width:'100%'}}>
                                        <h3 style={{color:"black"}}>Ödeme başarısız</h3>
                                    </div>
                                    <p>
                                        {errMsg}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <p>Lütfen tekrar deneyiniz</p>
                        </div>
                    </div>
                </Dialog>
            )
        }
    }

    useEffect(()=>{
        if(location.search){
            setShowPaymentFailMessage(true);
            setErrMsg(location.search.split("=")[1]);
        }
    },[location.search])

    useEffect(() => {
        setLoading(true);
        loadData();

    }, []);

    useEffect(()=>{
        const queryParams = new URLSearchParams(location.search)

        if (!showPaymentFailMessage && errMsg){
            if (queryParams.has('message')) {
                queryParams.delete('message')
                history.replace({
                    search: queryParams.toString(),
                })
                setErrMsg(null);
            }
        }
    },[showPaymentFailMessage])

    const loadData = async () => {

        setDisplayDialog(false);

        const data = {
            page: currentPage,
            size: 10,
        };

        let response;

        try {
             response = await appointmentService.getAllAppointments(data);
        }catch (e){
            setLoading(false);
        }

        if (response.success) {
            setAppointmentList(response.object);
            setTotalPage(response.row);
            setLoading(false);
        } else {
            console.log(apiMessageConstant.ERROR);
            toast.current.show({ severity: "error", summary: "Mesaj", detail: apiMessageConstant.ERROR });
            setLoading(false);
        }
    };

    const doctorBodyTemplate = (rowData) => {
        return rowData.doctor.unvan + " " + rowData.doctor.name + " " + rowData.doctor.surname;
    };

    const userBodyTemplate = (rowData) => {
        return rowData.userProfile.name + " " + rowData.userProfile.surname;
    };

    const odemeDurumuBodyTemplate = (rowData) => {
        if (rowData.paymentStatusText === "ODEME_ALINDI") return <span className={`product-badge status-odendi`}>{rowData.paymentStatusText}</span>;
        else if (rowData.paymentStatusText === "IADE") return <span className={`product-badge status-odenmedi`}>{rowData.paymentStatusText}</span>;
        else return <span className={`product-badge status-odenmedi`}>{rowData.paymentStatusText}</span>;
    };

    const appointmentTimeBodyTemplate = (date,bold) => {
        return <span style={ bold ? {fontWeight:'bold'} : {fontWeight : 'initial'}}>{format(new Date(date),'dd/MM/yyyy HH:mm')}</span>;
    };

    const randevuDurumuBodyTemplate = (rowData) => {
        if (rowData.canceled) return <span className={`product-badge status-odenmedi`}>İptal Edildi</span>;
        else if (rowData.completed) return <span className={`product-badge status-odendi`}>Tamamlandı</span>;
        if (!rowData.completed && !rowData.canceled) return <span className={`product-badge status-created`}>Randevu Oluşturuldu</span>;
    };

    const randevuTipiBodyTemplate = (rowData) => {
        let type = rowData.appointmentType === 'NORMAL' ? 'Normal' : rowData.appointmentType === 'EMERGENCY' ? 'Acil' : '';
        return <span>{type}</span>
    };

    const paymentDialogShow = (rowData) => {
        setSelectedRowData(rowData);
        setShowPaymentDialog(true);
    };

    const refundDialogShow = (rowData) => {
        setSelectedRowData(rowData);
        setShowRefundDialog(true);
    };

    const editButtonActionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button disabled={rowData.completed || rowData.canceled} icon="pi pi-pencil" className="p-button-rounded p-button-success" onClick={() => editAppoinment(rowData)} />
            </React.Fragment>
        );
    };

    const paymentButtonActionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button disabled={rowData.paymentStatusText !== 'ODEME_BEKLIYOR' || rowData.completed || rowData.canceled} className="p-button-danger" aria-label="Amazon" onClick={() => paymentDialogShow(rowData)}>
                    <span className="px-3">Ödeme Al</span>
                </Button>
            </React.Fragment>
        );
    };

    const refundButtonActionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button disabled={rowData.paymentStatusText !== 'ODEME_ALINDI'} className="p-button-warning" aria-label="Amazon" onClick={() => refundDialogShow(rowData)}>
                    <span className="px-3">Ödeme İade</span>
                </Button>
            </React.Fragment>
        );
    };

    const createAppointment = () => {
        setMode(MODE.CREATE);
        setSelectedAppoinment(null);
        setDisplayDialog(!displayDialog);
    };

    const editAppoinment = (rowData) => {
        setMode(MODE.UPDATE);
        setSelectedAppoinment(rowData);
        setDisplayDialog(true);
    };

    const paymentDialogClose = () => {
        setShowPaymentDialog(false);
    };

    const refundDialogClose = () => {
        setShowRefundDialog(false);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Randevu Oluştur" icon="pi pi-plus" className="p-button-success mr-2" onClick={createAppointment} />
            </React.Fragment>
        );
    };


    const pageChange =async(i)=> {

        setLoading(true);

            const data = {
                page: i,
                size: 10,
            };

            const responese = await appointmentService.getAllAppointments(data);
            if (responese.success) {
                setAppointmentList(responese.object);
                setTotalPage(responese.row);
                setLoading(false);
            } else {
                toast.current.show({ severity: "error", summary: "Mesaj", detail: apiMessageConstant.ERROR });
                setLoading(false);
            }
    }

    const [first1, setFirst1] = useState(0);
    const [rows1, setRows1] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const elements = (options) => {
        let elements = [];
        for (let i = 1; i <= totalPage; i++) {
            var btn = (
                <button type="button" className={options.className} onClick={()=>pageChange(i)}>
                    {i}
                    <Ripple />
                </button>
            );

            elements.push(btn);
        }

        return elements;
    };

    const template1 = {
        layout: "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
        PrevPageLink: (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Previous</span>
                    <Ripple />
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Next</span>
                    <Ripple />
                </button>
            );
        },
        PageLinks: (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { "p-disabled": true });

                return (
                    <span className={className} style={{ userSelect: "none" }}>
                        ...
                    </span>
                );
            }

            return <div>{elements(options)}</div>;
        },
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: "All", value: options.totalRecords },
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        },
    };

    const onCustomPage1 = (event) => {
        setFirst1(event.first);
        setRows1(event.rows);
        setCurrentPage(event.page + 1);
    };

    return (
        <div className="grid">
            <Toast ref={toast}></Toast>
            <div className="col-12">
                <div className="card">
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                    <DataTable value={appointmentList}
                               scrollable
                               loading={loading} paginator paginatorTemplate={template1} first={first1} rows={rows1} onPage={onCustomPage1}>
                        <Column field="id" header="ID"></Column>
                        <Column style={{minWidth:'150px'}} body={doctorBodyTemplate} header="Doktor Bilgisi"></Column>
                        <Column style={{minWidth:'150px'}} body={userBodyTemplate} header="Kullanıcı Bilgisi"></Column>
                        <Column style={{minWidth:'150px'}} body={(row)=>appointmentTimeBodyTemplate(row.createDateAppoinment,false)} field="createDateAppoinment" header="Randevu Oluşturulma Zamanı"></Column>
                        <Column style={{minWidth:'160px'}} body={(row)=>appointmentTimeBodyTemplate(row.appointmentTime,true)} field="appointmentTime" header="Randevu Zamanı"></Column>
                        <Column style={{minWidth:'150px'}} body={odemeDurumuBodyTemplate} header="Ödeme Durumu"></Column>
                        <Column style={{minWidth:'150px'}} body={randevuDurumuBodyTemplate} header="Randevu Durumu"></Column>
                        <Column style={{minWidth:'150px'}} body={randevuTipiBodyTemplate} header="Randevu Tipi"></Column>
                        <Column style={{ minWidth: "6rem" }} body={editButtonActionBodyTemplate} exportable={false}></Column>
                        <Column style={{ minWidth: "12rem" }} body={paymentButtonActionBodyTemplate} exportable={false} ></Column>
                        <Column style={{ minWidth: '13rem' }} body={refundButtonActionBodyTemplate} exportable={false} ></Column>
                    </DataTable>
                </div>
            </div>
            <DialogCustom header= { mode === MODE.CREATE ? "Randevu Oluştur" : "Randevu Güncelle" } displayDialog={displayDialog} dialogHide={createAppointment}>
                <AppoinmentForm refreshAppointments={loadData} mode={mode} selectedAppoinment={selectedAppoinment} />
            </DialogCustom>
            <DialogCustom header="Ödeme Ekranı" displayDialog={showPaymentDialog} dialogHide={paymentDialogClose}>
                <PaymentForm data={selectedRowData}/>
            </DialogCustom>
            <DialogCustom width={{width:'50%'}} header="İade Ekranı" displayDialog={showRefundDialog} dialogHide={refundDialogClose}>
                <RefundForm refreshAppointments={loadData} data={selectedRowData} close={()=>setShowRefundDialog(false)}/>
            </DialogCustom>
            {renderFailMessage()}
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Appointment, comparisonFn);
