import React, {useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import AuthContext from "./store/auth/auth-context";


export const AppTopbar = (props) => {

    const authCtx = useContext(AuthContext);

    return (
        <div className="layout-topbar">
            <Link to="/dashboard" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo.png' : 'assets/layout/images/logo.png'} alt="logo"/>
            </Link>

            <Button type="button" className="p-link layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </Button>

            <Button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </Button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                    <li>
                        <Button className="p-link layout-topbar-button" onClick={()=> authCtx.logout()}>
                             <i className="pi pi-sign-out"></i>
                            <span className="px-3">Çıkış</span>
                        </Button>
                    </li>
                </ul>
        </div>
    );
}
