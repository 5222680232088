import { pagePath } from '../environments/PagePath';
import { ApiService } from './ApiService';
export class BranchService {
    _apiService = new ApiService();

    getAllBranch() {
        return this._apiService.get(pagePath.Branch+'/getAll').then(res => res)
    }

    saveBirim(data){
        return this._apiService.post(pagePath.Branch+'/save',data).then(res => res)
    }

    deleteBirim(data){
        return this._apiService.get(pagePath.Branch+'/delete?id='+data).then(res => res)
    }

}