import { Dropdown } from "primereact/dropdown";
import React, {useState, useEffect} from "react";
import { Button } from "primereact/button";
import { AppointmentService } from "../../service/AppointmentService";
import DialogCustom from "../dialog/DialogCustom";
import {DoctorService} from "../../service/DoctorService";
import {UserService} from "../../service/UserService";
import {BranchService} from "../../service/BranchService";
import UserFormWithFormik from "../../pages/user/UserFormWithFormik";
import DateAndTimePicker from "../../pages/appointment/DateAndTimePicker";
import {APPOINTMENT_TYPE, CURRENCY, MODE} from "../../environments/GlobalConstant";
import {useFormik} from "formik";
import swal from "sweetalert";
import {classNames} from "primereact/utils";
import {format} from "date-fns";

const AppoinmentForm = (props) => {

    const {mode,selectedAppoinment} = props;

    const [hour, setHour] = useState(null);
    const [calendarId, setCalendarId] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [appointmentTime, setAppointmentTime] = useState(null);
    const [displayDialog,setDisplayDialog]=useState(false);
    const [branchList,setBranchList]=useState([]);
    const [doctorList,setDoctorList]=useState([]);
    const [userList,setUserList]=useState([]);

    const appointmentService = new AppointmentService();
    const branchService = new BranchService();
    const doctorService = new DoctorService();
    const userService = new UserService();


    const formik = useFormik({
        initialValues: {
            userId: '',
            branchId: '',
            doctorId: '',
            currency : ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.userId) {
                errors.userId = 'Hasta/Kullanıcı zorunlu.';
            }

            if (!data.branchId) {
                errors.branchId = 'Branş zorunlu.';
            }

            if (!data.doctorId) {
                errors.doctorId = 'Doktor zorunlu.';
            }

            if (!data.currency) {
                errors.currency = 'Para birimi zorunlu.';
            }

            return errors;
        },
        onSubmit: async (data) => {
            try {

                if (!hour){
                    swal("Uyarı", "Lütfen saat seçiniz.", "warning");
                    return;
                }

                let request;

                if (mode === MODE.UPDATE){
                    //alert(format(new Date(selectedAppoinment.appointmentTime),'yyyy/MM/dd HH:mm') + "change to" + getAppointmentTime())

                    let appointmentTime = getAppointmentTime();

                    request={
                        id : selectedAppoinment.id,
                        doctorCalendarId: calendarId,
                        appointmentTime
                    }

                    let resAppointment =  await appointmentService.update(request);

                    if (resAppointment){
                        if (resAppointment.success === true){
                            swal("Başarılı",resAppointment.message,"success");
                            props.refreshAppointments();
                        }else{
                            if (resAppointment.message){
                                swal("Uyarı",resAppointment.message,"warning");
                            }
                        }
                    }


                }else if(mode === MODE.CREATE){
                    let appointmentTime = getAppointmentTime();

                    request={
                        userId: data.userId,
                        doctorId: data.doctorId,
                        doctorCalendarId: calendarId,
                        appointmentTime,
                        paymentDataDto:{
                            currency: formik.values.currency.name
                        },
                        appointmentType : APPOINTMENT_TYPE.NORMAL,
                        createdBy : "ADMIN"
                    }

                    let resAppointment =  await appointmentService.save(request);

                    if (resAppointment){
                        if (resAppointment.success === true){
                            swal("Başarılı",resAppointment.message,"success");
                            props.refreshAppointments();
                        }else{
                            if (resAppointment.message){
                                swal("Uyarı",resAppointment.message,"warning");
                            }
                        }
                    }

                }
            }catch (e){
                console.log(e);
            }
        }
    });

    useEffect(() => {
        if (mode === MODE.UPDATE){
            formik.setValues({
                userId: selectedAppoinment.userProfile.userId,
                branchId: selectedAppoinment.doctor.branch.id,
                doctorId: selectedAppoinment.doctor.id,
                currency : { name: selectedAppoinment.currency, code: selectedAppoinment.currency }
            });

            setCalendarId(selectedAppoinment.doctorCalendarId);

            let formattedDate = format(new Date(selectedAppoinment.appointmentTime),'yyyy/MM/dd HH:mm');
            let str = formattedDate.split(" ");
            setHour(str[1]);
            setSelectedDate(new Date(str[0]));
        }

        loadData();

    },[]);

    useEffect(()=>{
        if (formik.values.branchId){
            doctorService.getAllDoctorsByBranchId(formik.values.branchId).then(res=>{
                if (res.success) {
                    let objectNew = [];
                    res.object.forEach(doctor=>{
                        objectNew.push({...doctor,nameSurname: doctor.name + " " + doctor.surname})
                    })
                    setDoctorList(objectNew);
                } else {
                }
            });
        }
    },[formik.values.branchId])

    useEffect(()=>{
        if (selectedDate){
            let time = getAppointmentTime();
            setAppointmentTime(time);
        }
    },[selectedDate,hour]);

    const loadData=()=>{
        getAllUsers();
        getAllBranches();
    }

    const getAllUsers = () =>{
        userService.getAllUsers().then(res=>{
            if (res.success) {
                let objectNew = [];
                res.object.forEach(user=>{
                    objectNew.push({...user,id: user.userId,nameSurnamePhone: user.name + " " + user.surname + " ( " + user.phone + " ) "})
                })
                setUserList(objectNew);
            } else {
            }
        });
    }

    const getAllBranches = () =>{
        branchService.getAllBranch().then(res=>{
            if (res.success) {
                setBranchList(res.object);
            } else {
            }
        });
    }

    const getSelectedTimeText=()=>{
        if (selectedDate){
            return <div style={{display:"flex",justifyContent:"center"}}>
                Seçilen Randevu Zamanı : {appointmentTime}
            </div>
        }
        return  "";
    }

    const getAppointmentTime=()=>{
        let date = new Date(selectedDate);
        let day = date.getDate();
        let month = addZero("" + (date.getMonth() + 1));
        let year = date.getFullYear();
        return  day + '/' + month + '/' + year + ' ' + ( hour ? hour : '' );
    }

    const addZero=(str)=>{
        return str.length == 1 ? '0' + str : str;
    }

    const dialogHide =()=> {
      setDisplayDialog(!displayDialog);
    }

    const doctorItemTemplate=(option)=>{
        return (
            <div>{option.nameSurname}</div>
        );
    }

    const userItemTemplate=(option)=>{
        return (
            <div>{option.nameSurnamePhone}</div>
        );
    }

    const _setValue=(name,value)=>{
        formik.setFieldValue(name,value);
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <div>
            <div className="card">
                <div className="grid">
                    <form style={{width:'100%'}} onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="col-12">
                            <div className="p-fluid">
                                <div className="field">
                                    <label htmlFor="userId" className={classNames({ 'p-error': isFormFieldValid('userId') })}>Hasta / Kullanıcı*</label>
                                    <div className="p-inputgroup">
                                        <Dropdown id="userId"
                                                  className={classNames({ 'p-invalid': isFormFieldValid('userId') })}
                                                  value={formik.values.userId}
                                                  placeholder="Hasta/Kullanıcı Seçiniz"
                                                  emptyFilterMessage="Kullanıcı bulunamadı"
                                                  filter
                                                  filterBy="nameSurnamePhone"
                                                  disabled={mode === MODE.UPDATE}
                                                  onChange={(e)=>{
                                                      _setValue("userId",e.value);
                                                  }}
                                                  itemTemplate={userItemTemplate}
                                                  options={userList} optionLabel="nameSurnamePhone" optionValue="id" />
                                    { mode === MODE.CREATE && <Button label="Yeni Kullanıcı Oluştur" onClick={dialogHide}/>}
                                    </div>
                                    {getFormErrorMessage('userId')}
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="p-fluid">
                                <div className="field">
                                    <label htmlFor="branchId" className={classNames({ 'p-error': isFormFieldValid('branchId') })}>Branş*</label>
                                    <Dropdown id="branchId"
                                              className={classNames({ 'p-invalid': isFormFieldValid('branchId') })}
                                              value={formik.values.branchId}
                                              filter
                                              filterBy="birimAdi"
                                              placeholder="Branş Seçiniz"
                                              disabled={mode === MODE.UPDATE}
                                              emptyFilterMessage="Branş bulunamadı"
                                              onChange={(e)=>{
                                                  _setValue("branchId",e.value);
                                              }}
                                              options={branchList} optionLabel="birimAdi" optionValue="id" />
                                </div>
                                {getFormErrorMessage('branchId')}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="p-fluid">
                                <div className="field">
                                    <label htmlFor="branchId" className={classNames({ 'p-error': isFormFieldValid('currency') })}>Para Birimi*</label>
                                    <Dropdown value={formik.values.currency}
                                              className={classNames({ 'p-invalid': isFormFieldValid('currency') })}
                                              options={CURRENCY}
                                              disabled={mode === MODE.UPDATE}
                                              onChange={(e)=>{
                                                  _setValue("currency",e.value);
                                              }}
                                              optionLabel="name"
                                              placeholder="Para birimi seç" />
                                    {getFormErrorMessage('currency')}
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="p-fluid">
                                <div className="field">
                                    <label htmlFor="doctorId" className={classNames({ 'p-error': isFormFieldValid('doctorId') })}>Doktor*</label>
                                    <Dropdown id="doctorId" emptyMessage="Önce branş seçiniz"
                                              className={classNames({ 'p-invalid': isFormFieldValid('doctorId') })}
                                              itemTemplate={doctorItemTemplate}
                                              value={formik.values.doctorId}
                                              filter
                                              filterBy="nameSurname"
                                              disabled={mode === MODE.UPDATE}
                                              placeholder="Doktor Seçiniz"
                                              emptyFilterMessage="Doktor bulunamadı"
                                              onChange={(e)=> {
                                                  _setValue("doctorId",e.value);
                                              }}
                                              options={doctorList} optionLabel="nameSurname" optionValue="id" />
                                </div>
                                {getFormErrorMessage('doctorId')}
                            </div>
                        </div>
                        {formik.values.doctorId &&<div className="col-12">
                            <div className="p-fluid">
                                <div className="field">
                                    <label htmlFor="country">Randevu Zamanı</label>
                                    <DateAndTimePicker doctorId={formik.values.doctorId}
                                                       setSelectedDate={setSelectedDate}
                                                       mode={mode}
                                                       selectedDate={selectedDate}
                                                       selectedHour={hour}
                                                       setSelectedHour={setHour}
                                                       setSelectedDoctorCalendarId={setCalendarId}/>
                                </div>
                                {getSelectedTimeText()}
                            </div>
                        </div>}
                        <div className="col-12">
                            <Button  type="submit"  label= { mode === MODE.CREATE ? "Randevu Oluştur" : "Randevu Güncelle"}/>
                        </div>
                    </form>
                </div>
            </div>

        <DialogCustom header="Kullanıcı Oluştur" displayDialog={displayDialog} dialogHide={dialogHide}>
              <UserFormWithFormik
                  mode={MODE.OTHER}
                  close={dialogHide}
                  refreshUserList={getAllUsers}
                  setUserId={(val) => _setValue("userId",val)}
              />
         </DialogCustom>
        </div>
    );
};

export default AppoinmentForm;
