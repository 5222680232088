import { pagePath } from '../environments/PagePath';
import { ApiService } from './ApiService';
export class ParameterService {
    _apiService = new ApiService();

    findAllParametersByParameterType(type) {
        return this._apiService.get(pagePath.Parameter+'/findAllParametersByParameterType/'+type).then(res => res)
    }

}
