import { pagePath } from '../environments/PagePath';
import { ApiService } from './ApiService';

export class DashboardService {

    _apiService = new ApiService();

    getInfo(){
        return this._apiService.get(pagePath.Dashboard+'/info').then(res=>res);
    }
}
