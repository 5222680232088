import React from "react";
import "./payment.ok.css";
import paymentimg from "../../assets/img/paymentapproved.png"
import {useHistory} from "react-router-dom";

export const PaymentOk=() =>{

    const history = useHistory();

    return(

        <div className="payment-container">
                <div className="payment-status">
                    <div className="payment-img">
                        <img src={paymentimg} />
                    </div>
                    <div className="payment-text">
                        <b>    Ödemeniz <br/> Başarıyla Alınmıştır.</b>
                    </div>
                    <div  className="payment-text1" >
                      DOCTORDEDİ’yi Tercih Ettiğiniz <br/>İçin Teşekkür Ederiz. Sağlıklı Günler Dileriz.
                    </div>
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <div  className="payment-text1" >
                            <a style={{color:'blue',cursor: "pointer"}} onClick={()=> history.push('appointment')}>Randevulara Git</a>
                        </div>
                    </div>
                </div>
        </div>

        );

}

