import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { UserService } from "../../service/UserService";
import { Image } from "primereact/image";
import { Divider } from "primereact/divider";
import { confirmDialog } from "primereact/confirmdialog";
import swal from 'sweetalert'
import {useFormik} from "formik";
import { classNames } from 'primereact/utils';
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import {MODE} from "../../environments/GlobalConstant";
import {Avatar} from "primereact/avatar";
import {FileUpload} from "primereact/fileupload";
import {ProgressSpinner} from "primereact/progressspinner";

const UserFormWithFormik = (props) => {
    const [phone, setPhone] = useState(null);
    const [id, setId] = useState(null);
    const [newPassword, setNewPassword] = useState("");
    const [photoUrl,setPhotoUrl] = useState(null);
    const [photoLoading,setPhotoLoading] = useState(false);

    const { user , mode, showResultMessage } = props;

    const userService = new UserService();

    useEffect(()=> {
        if (mode === MODE.UPDATE){
            formik.setValues({
                ...user
            })
            setPhone(user.phone);
            getProfilePhoto();
        }
    },[] )

    const confirmPassword = () => {
        confirmDialog({
            message: "Kullanıcya yeniden şifre oluşturulacaktır, devam etmek ister misiniz?",
            header: "Onay",
            icon: "pi pi-exclamation-triangle",
            accept: passowrdAccept,
        });
    };

    const passowrdAccept = async () => {
        const response = await userService.generatePassword(user.id);
        if (response.success) {
            setNewPassword(response.object);
        }
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            email: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Kullanıcı adı zorunlu.';
            }

            if (!data.surname) {
                errors.surname = 'Kullanıcı soyadı zorunlu.';
            }

            if (!data.email) {
                errors.email = 'Kullanıcı email zorunlu.';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Geçersiz email formatı . Örn. example@email.com';
            }

            return errors;
        },
        onSubmit: (data) => {

            if (phone === undefined || phone === null || phone === '' || !isValidPhoneNumber(phone)){
                swal("Uyarı","Lütfen telefon numaranızı kontrol ediniz.","warning")
                return;
            }

            if (mode === MODE.CREATE || mode === MODE.OTHER){
                userService.saveUser({...data,phone}).then(response=>{
                    if (response.success) {
                        formik.resetForm();
                        if (props.close){
                            props.close();
                        }
                        if (props.refreshUserList){
                            props.refreshUserList();
                        }
                        if (props.setUserId){
                            props.setUserId(response.object.id);
                        }

                        if (showResultMessage){
                            if (response.message){
                                swal("Başarılı",response.message,"success");
                            }
                        }
                    }else{
                        if (response.message){
                            swal("Uyarı",response.message,"warning");
                        }
                    }
                });
            }else if (mode === MODE.UPDATE){
                userService.updateUser({...data,phone}).then(response=>{
                    if (response.success) {
                        formik.resetForm();
                        if (props.close){
                            props.close();
                        }
                        if (props.refreshUserList){
                            props.refreshUserList();
                        }

                        if (showResultMessage){
                            if (response.message){
                                swal("Başarılı",response.message,"success");
                            }
                        }
                    }else{
                        if (response.message){
                            swal("Uyarı",response.message,"warning");
                        }
                    }
                });
            }

        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getProfilePhoto=()=>{
        userService.findById(user.id).then(res=>{
            if (res && res.success){
                setPhotoUrl(res.object.profilePhotoUrl);
            }
        })
    }

    const uploadProfilePhoto=(file)=>{
        setPhotoLoading(true);
        userService.updateProfilePhoto(file,user.id).then(res=>{
            if (res && res.success){
                swal("Başarılı", res.message, "success");
                getProfilePhoto();
                setPhotoLoading(false);
            }else{
                swal("Uyarı", res.message, "warning");
                setPhotoLoading(false);
            }
        }).catch(e=>{
                setPhotoLoading(false);
          }
        )
    }

    const renderLoading=()=>{
        return(
            <div className="container" style={{display:"flex",justifyContent:"center"}}>
                <ProgressSpinner style={{width: '30px', height: '30px'}} />
            </div>
        )
    }

    return (
        <div>
            { mode === MODE.UPDATE ? (
                <div className="card">
                    <div className="grid">
                        { mode === "UPDATE" && <div className="col-md-6  card">
                            <div className="p-fluid flex align-items-center justify-content-center">
                                <Avatar image={photoUrl} size="xlarge" shape="circle" style={{width:"8em",height:"8em"}} />
                            </div>
                            <div>
                                <FileUpload mode="basic"
                                            className='mt-1'
                                            name="demo[]"
                                            url="/"
                                            accept="image/*"
                                            maxFileSize={1000000}
                                            onSelect={(e) => {
                                                uploadProfilePhoto(e.files[0]);
                                            }}
                                            chooseLabel={"Yükle"}
                                            onUpload={() => { console.log('uploaded'); }}/>
                            </div>
                            <div>{photoLoading&&renderLoading()}</div>
                        </div> }
                        <div className="col-1">
                            <Divider layout="vertical"></Divider>
                        </div>
                        <div className="col-5">
                            <Button label="Yeni Şifre Oluştur" icon="pi pi-user-plus" className="p-button-success" onClick={confirmPassword}></Button>
                            <Divider />
                            {newPassword ? (
                                <p>
                                    <h5>Yeni Şifre : {newPassword}</h5>
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
            <div className="card">
                <div className="grid">
                    <form style={{width:'100%'}} onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="col-10">
                            <div className="p-fluid">
                                <div className="field">
                                    <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>Ad*</label>
                                    <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                    {getFormErrorMessage('name')}
                                </div>
                            </div>
                        </div>
                        <div className="col-10">
                            <div className="p-fluid">
                                <div className="field">
                                    <label htmlFor="surname" className={classNames({ 'p-error': isFormFieldValid('surname') })}>Soyad*</label>
                                    <InputText id="surname" name="surname" value={formik.values.surname} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('surname') })} />
                                    {getFormErrorMessage('surname')}
                                </div>
                            </div>
                        </div>

                        <div className="col-10">
                            <div className="p-fluid">
                                <div className="field">
                                    <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>Email*</label>
                                    <span className="p-float-label p-input-icon-right">
                                        <i className="pi pi-envelope" />
                                        <InputText id="email"
                                                   name="email"
                                                   value={formik.values.email}
                                                   disabled={mode === MODE.UPDATE}
                                                   onChange={formik.handleChange}
                                                   className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                                    </span>
                                    {getFormErrorMessage('email')}
                                </div>
                            </div>
                        </div>

                        <div className="col-10">
                            <div className="p-fluid">
                                <div className="field">
                                    <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('phone') })}>Telefon*</label>
                                    <PhoneInput
                                        placeholder="Telefon"
                                        value={phone}
                                        onChange={setPhone}
                                    />
                                    {getFormErrorMessage('phone')}
                                </div>
                            </div>
                        </div>

                        <div className="col-10 text-center">
                            <Button style={{width:'30%'}} type="submit" className="mt-2"label="Kaydet" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UserFormWithFormik;
