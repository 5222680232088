import React, {useContext, useEffect, useRef, useState} from 'react';
import { UserService } from '../../service/UserService';
import AuthContext from '../../store/auth/auth-context';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '../../assets/demo/flags/flags.css';
import '../../assets/demo/Demos.scss';
import '../../assets/layout/layout.scss';
import '../../App.scss';
import './login.css';
import {useHistory} from "react-router-dom";

const Login = () => {

    const history = useHistory();

    const authCtx = useContext(AuthContext);

    const userNameRef = useRef();
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(()=>{
        setError('');
    },[])


    const loginSubmitHandler = async(event) => {
        event.preventDefault();
        const data = {
            usernameOrEmail: userNameRef.current.value,
            password: password
        }

        const userService = new UserService();

        try {
            const response = await userService.login(data);

            if (response){
                if(!response.success && response.message){
                    setError(response.message);
                    return;
                }

                if(response.status) {
                    const expirationTime = new Date(
                        new Date().getTime() + 3000 * 1000
                    );
                    authCtx.login(response, expirationTime.toISOString());
                    setError('');
                    history.push('/')
                }
            }


        }catch (e){
            console.log(e);
        }

    }

    return (
        <div className="main-div">
            <div class="container" id="container">
                <div class="form-container sign-in-container">
                    <div class="social-container">
                        <img height='50px' src='assets/layout/images/logo.png'/>
                    </div>
                    <form action="#">
                        <h1>Kullanıcı Girişi</h1>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <span style={{padding:'3px'}}><i style={{fontSize: '2rem',color:'white'}} class="pi pi-user"></i></span>
                            <input className="loginInput" id="username" type="email" onChange={()=> setError('')} ref={userNameRef} placeholder="Username" placeholder="Kullanıcı Adı"/>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <span style={{padding:'3px'}}><i style={{fontSize: '2rem',color:'white'}} class="pi pi-lock"></i></span>
                            <input className="loginInput" id="password" type="password" value={password} placeholder="Password" onChange={(e) => {
                            setError('')
                            setPassword(e.target.value);
                        }}  type="password" placeholder="Parola"/>
                        </div>
                            <button onClick={loginSubmitHandler}>Giriş</button>
                        <div>
                            {error && <span style={{color:'white'}}>{error}</span>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;
