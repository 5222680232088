import React, { useContext } from "react"
import { Switch, Route, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import 'react-phone-number-input/style.css'
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import 'react-credit-cards/es/styles-compiled.css';
import Login from './pages/login/Login';
import AuthContext from "./store/auth/auth-context";
import MainPage from "./main/Main"

const App = () => {
    const authCtx = useContext(AuthContext);
    return (
        <div>
            <Switch>
            {!authCtx.isLoggedIn && (
                    <Route path='/login'>
                        <Login />
                    </Route>
                )}
                <Route path='/'>
                    {authCtx.isLoggedIn && <MainPage />}
                    {!authCtx.isLoggedIn && <Redirect to='/login' />}
                </Route>
                <Route path='*'>
                    <Redirect to='/dashboard' />
                </Route>
            </Switch>


        </div>
    );

}

export default App;
