import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DoctorService } from "../../service/DoctorService";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import { confirmDialog } from "primereact/confirmdialog";
import { ToggleButton } from "primereact/togglebutton";
import { FileUpload } from "primereact/fileupload";
import { Avatar } from 'primereact/avatar';
import { ProgressSpinner } from 'primereact/progressspinner';
import {TabPanel, TabView} from "primereact/tabview";
import PhotoGalleryEdit from "../../components/PhotoGalleryEdit";
import VideoGalleryEdit from "../../components/VideoGalleryEdit";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import MultiSelectComponent from "../../components/select/MultiSelectComponent";
import {useFormik} from "formik";
import { classNames } from 'primereact/utils';
import swal from 'sweetalert'
import {validateFacebookUrl, validateInstagramUrl, validateTwitterUrl, validateYouTubeUrlChannel} from "../../util/util";
import {BranchService} from "../../service/BranchService";
import {InputTextarea} from "primereact/inputtextarea";

const DoctorForm = (props) => {
    const [newPassword, setNewPassword] = useState("");
    const [phone, setPhone] = useState(null);
    const [photoUrl,setPhotoUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [branchList, setBranchList] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [photoLoading,setPhotoLoading] = useState(false);

    const doctorService = new DoctorService();
    const branchService = new BranchService();

    const { doctor, isUpdate , mode } = props;

    useEffect(()=> {
        if (props.isUpdate){
            formik.setValues({
                ...doctor,languages : JSON.parse(doctor.languages ? doctor.languages : null)
            })
            setPhone(doctor.phone);
            getProfilePhoto();
        }

        loadBrachList();

    },[] )

    const getProfilePhoto=()=>{
        doctorService.getByid(doctor.id).then(res=>{
            if (res && res.success){
                setPhotoUrl(res.object.profilePhotoUrl);
            }
        })
    }

    const loadBrachList = async () => {
        const res = await branchService.getAllBranch();
        if (res.success) {
            setBranchList(res.object);
        } else {
            console.log(res.message);
        }
    }

    //formik validation

    const formik = useFormik({
        initialValues: {
            unvan: '',
            name: '',
            surname: '',
            email: '',
            branch: null,
            profession: '',
            iban: '',
            perPriceWithTl: '',
            perPriceWithDolar:'',
            perPriceWithEuro:'',
            perPriceWithGbp:'',
            quePriceWithTl:'',
            quePriceWithDolar:'',
            quePriceWithEuro:'',
            quePriceWithGbp:'',
            instagram: '',
            facebook: '',
            twitter: '',
            youtube: '',
            aciklama:'',
            languages: null
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'İsim zorunlu.';
            }
            if (!data.surname) {
                errors.surname = 'Soyisim zorunlu.';
            }

            if (!data.email) {
                errors.email = 'Email zorunlu.';
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'E-mail zorunlu. example@email.com';
            }

            if (!data.branch) {
                errors.branch = 'Birim zorunlu.';
            }

            if (!data.iban) {
                errors.iban = 'IBAN zorunlu.';
            }

            if (!data.perPriceWithTl) {
                errors.perPriceWithTl = 'Ücret zorunlu.';
            }

            if (!data.perPriceWithDolar) {
                errors.perPriceWithDolar = 'Ücret zorunlu.';
            }

            if (!data.perPriceWithEuro) {
                errors.perPriceWithEuro = 'Ücret zorunlu.';
            }

            if (!data.perPriceWithGbp) {
                errors.perPriceWithGbp = 'Ücret zorunlu.';
            }

            if (!data.quePriceWithTl) {
                errors.quePriceWithTl = 'Ücret zorunlu.';
            }

            if (!data.quePriceWithDolar) {
                errors.quePriceWithDolar = 'Ücret zorunlu.';
            }

            if (!data.quePriceWithEuro) {
                errors.quePriceWithEuro = 'Ücret zorunlu.';
            }

            if (!data.quePriceWithGbp) {
                errors.quePriceWithGbp = 'Ücret zorunlu.';
            }

            if (!data.aciklama) {
                errors.aciklama = 'Açıklama zorunlu.';
            }

            if (!data.languages || data.languages.length == 0) {
                errors.languages = 'En az bir dil seçmelisiniz.';
            }

            if (!data.unvan) {
                errors.unvan = 'Unvan zorunlu.';
            }

            return errors;
        },
        onSubmit: (data) => {

            if (phone === undefined || phone === null || phone === '' || !isValidPhoneNumber(phone)){
                swal("Uyarı","Lütfen telefon numaranızı kontrol ediniz.","warning")
                return;
            }

            if (data.instagram){
                if (!validateInstagramUrl(data.instagram)){
                    swal("Uyarı","Lütfen geçerli bir instagram linki girin.","warning");
                    return;
                }
            }

            if (data.facebook){
                if (!validateFacebookUrl(data.facebook)){
                    swal("Uyarı","Lütfen geçerli bir facebook linki girin.","warning");
                    return;
                }
            }

            if (data.twitter){
                if (!validateTwitterUrl(data.twitter)){
                    swal("Uyarı","Lütfen geçerli bir twitter linki girin.","warning");
                    return;
                }
            }

            if (data.youtube){
                if (!validateYouTubeUrlChannel(data.youtube)){
                    swal("Uyarı","Lütfen geçerli bir youtube linki girin.","warning");
                    return;
                }
            }

            if (data.iban&&data.iban.length<26){
                swal("Uyarı", "Iban min 26 karakter olabilir.", "warning");
                return;
            }

            setLoading(true);

            if(!props.isUpdate){//create
                doctorService.saveDoctor({...data,phone,languages: JSON.stringify(data.languages)}).then(res=>{
                    if (res && res.success){
                        swal("Başarılı", res.message, "success");
                        setLoading(false);
                        props.refreshData();
                        props.close();
                    }else{
                        swal("Uyarı", res.message, "warning");
                        setLoading(false);
                    }
                }).catch(e=>{
                    setLoading(false);
                })
            }else{
                const oldEmail = doctor.email;
                const oldPhone = doctor.phone;
                doctorService.updateDoctor({...data,id:doctor.id,phone,oldEmail,oldPhone,languages: JSON.stringify(data.languages)}).then(res=>{
                    if (res && res.success){
                        swal("Başarılı", res.message, "success");
                        setLoading(false);
                        props.refreshData();
                        props.close();
                    }else{
                        swal("Uyarı", res.message, "warning");
                        setLoading(false);
                    }
                }).catch(e=>{
                    setLoading(false);
                })
            }

        }
    });


    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const confirmPassword = () => {
        confirmDialog({
            message: "Kullanıcya yeniden şifre oluşturulacaktır, devam etmek ister misiniz?",
            header: "Onay",
            icon: "pi pi-exclamation-triangle",
            accept: passowrdAccept,
        });
    };

    const onchangeDialogApproved = (e) => {
        if (e === true) {
            confirmDialog({
                message: "Onaylıyor musunuz?",
                header: "Onay",
                icon: "pi pi-exclamation-triangle",
                accept: approveDoctor,
            });
        } else {
            confirmDialog({
                message: "Onaylıyor musunuz?",
                header: "Onay",
                icon: "pi pi-exclamation-triangle",
                accept: approveDoctor,
            });
        }
    };

    const approveDoctor = async () => {
        const res = await doctorService.approveDoctor(doctor.id);
        if (res && res.success){
            swal("Başarılı", res.message, "success");
            setLoading(false);
            props.refreshData();
            props.close();
        }else{
            swal("Uyarı", res.message, "warning");
            setLoading(false);
        }
    };

    const passowrdAccept = async () => {
        const response = await doctorService.generatePassword(doctor.id);
        if (response.success) {
            setNewPassword(response.object);
        }
    };

    const phoneOnChange=(val)=>{
        setPhone(val);
    }

    const renderLoading=()=>{
        return(
            <div className="container" style={{display:"flex",justifyContent:"center"}}>
                <ProgressSpinner style={{width: '30px', height: '30px'}} />
            </div>
        )
    }

    const uploadProfilePhoto=(file)=>{
        setPhotoLoading(true);
        doctorService.updateProfilePhoto(file,doctor.id).then(res=>{
            if (res && res.success){
                swal("Başarılı", res.message, "success");
                getProfilePhoto();
                setPhotoLoading(false);
            }else{
                swal("Uyarı", res.message, "warning");
                setPhotoLoading(false);
            }
        }).catch(e=>{
            setPhotoLoading((false));
        })
    }

    return (
        <div className="row">
            <TabView activeIndex={selectedTab}  onTabChange={(e) => setSelectedTab(e.index)}>
                <TabPanel header="Doktor">
                    <div>
                    <form onSubmit={formik.handleSubmit} >
                        <div className="grid">
                            <div className="col-12">
                                { mode === "UPDATE" && <div className="card">
                                        <div className="p-fluid flex align-items-center justify-content-center">
                                            <Avatar image={isUpdate ? photoUrl : ""} size="xlarge" shape="circle" style={{width:"8em",height:"8em"}} />
                                        </div>
                                      <div>
                                        <FileUpload mode="basic"
                                                    className='mt-1'
                                                    name="demo[]"
                                                    url="/"
                                                    accept="image/*"
                                                    maxFileSize={1000000}
                                                    onSelect={(e) => {
                                                        uploadProfilePhoto(e.files[0]);
                                                    }}
                                                    chooseLabel={isUpdate ? "Değiştir": "Yükle"}
                                                    onUpload={() => { console.log('uploaded'); }}/>
                                    </div>
                                    <div>{photoLoading&&renderLoading()}</div>
                                </div> }
                            <div>
                            {(doctor.approved && doctor.status) && <Button type="reset" label="Yeni Şifre Oluştur" icon="pi pi-user-plus" className="p-button-success" onClick={confirmPassword}></Button>}

                            { mode === "UPDATE" &&
                                <ToggleButton checked={doctor.approved}
                                              onChange={(e) => onchangeDialogApproved(e.value)}
                                              onLabel={"Doktorun Onayını Kaldır"}
                                              offLabel={"Doktoru Onayla"}
                                              onIcon="pi pi-check"
                                              offIcon="pi pi-times"
                                              style={{ width: "15em",height:"3em",marginLeft:"3em" }}
                                              aria-label="Confirmation" />}
                                <Divider />
                                {newPassword ? (
                                    <p>
                                        <h5>Yeni Şifre : {newPassword}</h5>
                                    </p>
                                ) : (
                                    ""
                                )}
                        </div>

                                <div className="text-center mb-5 mt-5">
                                    <label className="font-bold">DOKTOR BİLGİLERİ</label>
                                </div>

                                <div className='col-md-12'>
                                    <div className='row'>

                                        <div className='col-md-4 pe-2'>
                                            <label htmlFor="unvan" className={classNames({ 'p-error': isFormFieldValid('unvan') })}>Unvan*</label>
                                            <InputText id="unvan" autoFocus name="unvan" value={formik.values.unvan} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('unvan') })} />
                                            {getFormErrorMessage('unvan')}
                                        </div>

                                        <div className='col-md-4 pe-2'>
                                            <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>İsim*</label>
                                            <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                            {getFormErrorMessage('name')}
                                        </div>

                                        <div className='col-md-4 pe-2'>
                                            <label htmlFor="surname" className={classNames({ 'p-error': isFormFieldValid('surname') })}>Soyisim*</label>
                                            <InputText id="surname" name="surname" value={formik.values.surname} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('surname') })} />
                                            {getFormErrorMessage('surname')}
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-md-6 pe-2'>
                                            <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>E-mail*</label>
                                            <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                                            {getFormErrorMessage('email')}
                                        </div>

                                        <div className='col-md-6 ps-2'>
                                            <div>
                                                <label>Telefon Numarası</label>
                                            </div>

                                            <PhoneInput
                                                //disabled={mode === "UPDATE"}
                                                className="form-control form-control-not-block"
                                                placeholder="Telefon"
                                                value={phone}
                                                onChange={phoneOnChange}
                                            />
                                        </div>
                                    </div>
                                            <>
                                                <div className='row mt-3'>
                                                    <div className='col-md-6 pe-2'>
                                                        <label htmlFor="branch" className={classNames({ 'p-error': isFormFieldValid('branch') })}>Birim*</label>
                                                        <Dropdown id="branch"
                                                                  name="branch"
                                                                  value={formik.values.branch?.id}
                                                                  options={branchList}
                                                                  optionLabel="birimAdi"
                                                                  placeholder="Birim Seçiniz"
                                                                  filter
                                                                  filterBy="birimAdi"
                                                                  onChange={(e) => formik.setFieldValue("branch.id",e.value)}
                                                                  optionValue="id"
                                                                  className={classNames({ 'p-invalid': isFormFieldValid('branch') })} />
                                                        {getFormErrorMessage('branch')}
                                                    </div>

                                                    <div className='col-md-6 pe-2'>
                                                        <div>
                                                            <label>Uzmanlık Alanı</label>
                                                        </div>

                                                        <InputText name="profession" value={formik.values.profession}
                                                                   onChange={formik.handleChange}
                                                        />
                                                        <span style={{fontSize:'12px',fontStyle:'italic'}}><i className="fa-solid fa-circle-info"></i>Birden fazla uzmanlık alanınız olması durumunda aralarına # ekleyerek giriş yapınız. <br/> Örn: Uzmanlık Alanı1#Uzmanlık Alanı 2 </span>
                                                    </div>
                                                </div>

                                                <div className='row mt-3'>
                                                    <div className='col-md-6'>
                                                        <div>
                                                            <label className={classNames({ 'p-error': isFormFieldValid('languages') })}>Diller*</label>
                                                        </div>
                                                        <MultiSelectComponent message={getFormErrorMessage('languages')} className={classNames({ 'p-invalid': isFormFieldValid('languages') })} languages={formik.values.languages} setLanguages={(languages) => formik.setFieldValue("languages",languages)}/>

                                                    </div>
                                                </div>

                                                <div className='row mt-3'>
                                                    <div className='col-md-12'>
                                                        <label htmlFor="iban" className={classNames({ 'p-error': isFormFieldValid('iban') })}>Iban*</label>
                                                        <InputText id="iban" name="iban" value={formik.values.iban} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('iban') })} />
                                                        {getFormErrorMessage('iban')}
                                                    </div>
                                                </div>

                                                <Divider align="center">
                                                    <span className="p-tag">Seans ve Soru Ücretleri</span>
                                                </Divider>

                                                <div className='row mt-3'>
                                                    <div className='col-md-3 pe-2'>
                                                        <div className='col-md-12'>
                                                            <label htmlFor="perPriceWithTl" className={classNames({ 'p-error': isFormFieldValid('perPriceWithTl') })}>Seans/TL*</label>
                                                            <InputText id="perPriceWithTl" name="perPriceWithTl" value={formik.values.perPriceWithTl} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('perPriceWithTl') })} />
                                                            {getFormErrorMessage('perPriceWithTl')}
                                                        </div>
                                                    </div>

                                                    <div className='col-md-3 pe-2'>
                                                        <div className='col-md-12'>
                                                            <label htmlFor="perPriceWithDolar" className={classNames({ 'p-error': isFormFieldValid('perPriceWithDolar') })}>Seans/Dolar*</label>
                                                            <InputText id="perPriceWithDolar" name="perPriceWithDolar" value={formik.values.perPriceWithDolar} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('perPriceWithDolar') })} />
                                                            {getFormErrorMessage('perPriceWithDolar')}
                                                        </div>
                                                    </div>

                                                    <div className='col-md-3'>
                                                        <div className='col-md-12'>
                                                            <label htmlFor="perPriceWithEuro" className={classNames({ 'p-error': isFormFieldValid('perPriceWithEuro') })}>Seans/Euro*</label>
                                                            <InputText id="perPriceWithEuro" name="perPriceWithEuro" value={formik.values.perPriceWithEuro} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('perPriceWithEuro') })} />
                                                            {getFormErrorMessage('perPriceWithEuro')}
                                                        </div>
                                                    </div>

                                                    <div className='col-md-3'>
                                                        <div className='col-md-12'>
                                                            <label htmlFor="perPriceWithGbp" className={classNames({ 'p-error': isFormFieldValid('perPriceWithGbp') })}>Seans/Sterlin*</label>
                                                            <InputText id="perPriceWithGbp" name="perPriceWithGbp" value={formik.values.perPriceWithGbp} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('perPriceWithGbp') })} />
                                                            {getFormErrorMessage('perPriceWithGbp')}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row mt-3'>
                                                    <div className='col-md-3'>
                                                        <div className='col-md-12'>
                                                            <label htmlFor="quePriceWithTl" className={classNames({ 'p-error': isFormFieldValid('quePriceWithTl') })}>Soru/TL*</label>
                                                            <InputText id="quePriceWithTl" name="quePriceWithTl" value={formik.values.quePriceWithTl} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('quePriceWithTl') })} />
                                                            {getFormErrorMessage('quePriceWithTl')}
                                                        </div>
                                                    </div>

                                                    <div className='col-md-3'>
                                                        <div className='col-md-12'>
                                                            <label htmlFor="quePriceWithDolar" className={classNames({ 'p-error': isFormFieldValid('quePriceWithDolar') })}>Soru/Dolar*</label>
                                                            <InputText id="quePriceWithDolar" name="quePriceWithDolar" value={formik.values.quePriceWithDolar} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('quePriceWithDolar') })} />
                                                            {getFormErrorMessage('quePriceWithDolar')}
                                                        </div>
                                                    </div>

                                                    <div className='col-md-3'>
                                                        <div className='col-md-12'>
                                                            <label htmlFor="quePriceWithEuro" className={classNames({ 'p-error': isFormFieldValid('quePriceWithEuro') })}>Soru/Euro*</label>
                                                            <InputText id="quePriceWithEuro" name="quePriceWithEuro" value={formik.values.quePriceWithEuro} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('quePriceWithEuro') })} />
                                                            {getFormErrorMessage('quePriceWithEuro')}
                                                        </div>
                                                    </div>

                                                    <div className='col-md-3'>
                                                        <div className='col-md-12'>
                                                            <label htmlFor="quePriceWithGbp" className={classNames({ 'p-error': isFormFieldValid('quePriceWithGbp') })}>Soru/Sterlin*</label>
                                                            <InputText id="quePriceWithGbp" name="quePriceWithGbp" value={formik.values.quePriceWithGbp} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('quePriceWithGbp') })} />
                                                            {getFormErrorMessage('quePriceWithGbp')}
                                                        </div>
                                                    </div>
                                                </div>

                                                <Divider align="center">
                                                    <span className="p-tag">Sosyal Medya Linkleri</span>
                                                </Divider>

                                                <div className='row mt-3'>
                                                    <div className='col-md-12 pe-2 mt-2'>
                                                        <label htmlFor="instagram" className={classNames({ 'p-error': isFormFieldValid('instagram') })}>Instagram</label>
                                                        <InputText id="instagram" name="instagram" value={formik.values.instagram} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('instagram') })} />
                                                        <span style={{fontSize:'12px',fontStyle:'italic'}}>Örn: https://instagram.com/doctordedi</span>
                                                    </div>

                                                    <div className='col-md-12 pe-2 mt-2'>
                                                        <label htmlFor="facebook" className={classNames({ 'p-error': isFormFieldValid('facebook') })}>Facebook</label>
                                                        <InputText id="facebook" name="facebook" value={formik.values.facebook} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('facebook') })} />
                                                        <span style={{fontSize:'12px',fontStyle:'italic'}}>Örn: https://facebook.com/doctordedi</span>
                                                    </div>

                                                    <div className='col-md-12 mt-2'>
                                                        <label htmlFor="twitter" className={classNames({ 'p-error': isFormFieldValid('twitter') })}>Twitter</label>
                                                        <InputText id="twitter" name="twitter" value={formik.values.twitter} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('twitter') })} />
                                                        <span style={{fontSize:'12px',fontStyle:'italic'}}>Örn: https://twitter.com/doctordedi</span>
                                                    </div>

                                                    <div className='col-md-12 mt-2'>

                                                        <label htmlFor="youtube" className={classNames({ 'p-error': isFormFieldValid('youtube') })}>Youtube</label>
                                                        <InputText id="youtube" name="youtube" value={formik.values.youtube} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('youtube') })} />
                                                        <span style={{fontSize:'12px',fontStyle:'italic'}}>Örn: https://youtube.com/@doctordedi</span>
                                                    </div>
                                                </div>

                                                <div className='row mt-3'>
                                                    <div className='col-md-12'>
                                                        <label htmlFor="aciklama" className={classNames({ 'p-error': isFormFieldValid('aciklama') })}>Biyografi</label>
                                                        <InputTextarea id="aciklama" name="aciklama" style={{height:'250px'}} value={formik.values.aciklama} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('aciklama') })} />
                                                    </div>
                                                </div>
                                            </>
                                </div>
                                {loading&&renderLoading()}
                        <div className="col-12 text-center">
                            {
                                !isUpdate ? <Button className="p-button-success" type="submit" label="Kaydet" icon="pi pi-check" />
                                    : <Button label="Güncelle" type="submit" icon="pi pi-check" />

                            }
                        </div>
                        </div>
                        </div>
                </form>
            </div>
                </TabPanel>
                { mode === "UPDATE" &&<TabPanel header="Fotoğraf Düzenle">
                    <div className="col-12">
                        <PhotoGalleryEdit doctorId={props.doctor.id}/>
                    </div>
                </TabPanel> }
                { mode === "UPDATE" && <TabPanel header="Video Düzenle">
                    <VideoGalleryEdit doctorId={props.doctor.id} />
                </TabPanel>}
            </TabView>
        </div>
    );
};

export default DoctorForm;
