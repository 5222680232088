import { ApiService } from './ApiService';
import {pagePath} from "../environments/PagePath";

export class DoctorCalendarService {

    _apiService = new ApiService();

    getDoctorAvailableHours(data){
        return this._apiService.post(pagePath.DoctorCalendar+'/availableHours',data).then(res => res)
    }

}
